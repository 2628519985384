
import './App.css';
import { toast } from 'react-toastify'
import {ethers}  from 'ethers';
import { APP_NAME } from './lib/consts';
import { shakaShareContract, shakaTokenContract } from './lib/contracts';
import ConnectWallet from './components/ConnectWallet';
//import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react';
import { useState, Fragment, useEffect } from 'react';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useEthers } from '@usedapp/core';
import provider from './ethersProvider.js';

import { Listbox, Transition } from '@headlessui/react';

function App() {
  const [tipSharesAmount, setTipSharesAmount] = useState("1");
  const [tokenAmount, setTokenAmount] = useState(1n);
  const tokenPerShare = ethers.utils.parseUnits("1", 18); // Changed to use parseUnits for consistency with big numbers
  const [tokenHash, setTokenHash] = useState('0x1111');
  const [selectToken, setSelectToken] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [balance, setBalance] = useState('');
  const { account, chainId } = useEthers()

  const checkBalance = async (tokenHash) => {

    try {
      // const alchemyUrl = 'https://base-mainnet.g.alchemy.com/v2/kX8tT8NvetwmHKARUdQkm2D15jhukcFZ';
      // const provider = new ethers.providers.JsonRpcProvider(alchemyUrl);
      const contract = new ethers.Contract(
        shakaShareContract.address,
        shakaShareContract.abi,
        provider
      )
  
      // Example addresses and hash
      console.log('address', account)
      const balance = await contract.balanceOfJars(tokenHash, account)
      console.log('balance', balance)
      setBalance(balance.toString());
    } catch (error) {
      console.error('Error checking balance:', error)
    }
  }

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const tokens = [
    {tokenSymbol: "$SHAKA", title: "$SHAKA 🤙", hashval: 	"0x1111"},
    {tokenSymbol: "Coming soon", title: "??? 📦", hashval: "0x111"},
  ];
  
  function isSelectedToken(token) {
    return token.hashval === tokenHash;
  }
  useEffect(() => {
  
  setTokenSymbol(tokens.find(isSelectedToken).tokenSymbol);
  console.log("selectToken", tokens.find(isSelectedToken).title);
  checkBalance(tokenHash);
  }, [tokenHash]);

  const handleBuyTipShares = async () => {
    if (!tipSharesAmount || !tokenAmount) {
      alert('Please fill in all fields');
      return;
    }

    try {
      let signer;
      if (typeof window !== "undefined" && window.ethereum) {
        signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
      }
      
      const tokenContract = new ethers.Contract(
        shakaTokenContract.address,
        shakaTokenContract.abi,
        signer
      );

      const shareContract = new ethers.Contract(
        shakaShareContract.address,
        shakaShareContract.abi,
        signer
      );

      const parsedTokenAmount = ethers.utils.parseUnits(tipSharesAmount, 18);
      
      const tokenTotal = ethers.BigNumber.from(tipSharesAmount).mul(tokenPerShare); // Ensure large number calculations are safe
	  console.log("tokenTotal", tokenTotal);
	  console.log("tipSharesAmount", tipSharesAmount);
	  console.log("tokenPerShare", tokenPerShare);
    const allowances = await tokenContract.allowance(
      signer.getAddress(),
      shakaShareContract.address
    )
    console.log("allowances", allowances);
    if (allowances.lt(tokenTotal)) {
      const id = toast.loading('Approving Tokens to be added to Tipper...')
	  const approveTx = await tokenContract.approve(shakaShareContract.address, tokenTotal);
      await approveTx.wait();
      toast.update(id, {
        render: `Tokens Approved! Loading Transaction...`,
        type: 'success',
        isLoading: false,
        closeButton: true,
        autoClose: 2000,
      })
      toast.dismiss(id);
    }
  
      const id = toast.loading('Depositing into Tipper...');
      const buyTx = await shareContract.depositIntoJar(tokenHash, tipSharesAmount, parsedTokenAmount);
      await buyTx.wait();
      toast.update(id, {
        render: `Transaction successful!`,
        type: 'success',
        isLoading: false,
        closeButton: true,
        autoClose: 5000,
      })
      toast.dismiss(id);
      //alert('Transaction successful!');
    } catch (error) {
      console.error('Transaction failed:', error);
      toast.error('Transaction failed. Connect wallet to Base network and try again.');
    }
  };


	
	return (
    <div>
		<div className="relative flex items-top justify-center min-h-screen bg-gray-100 dark:bg-gray-900 sm:items-center py-2">
			<div className="absolute top-2 right-6">
				<ConnectWallet />
        <ToastContainer
          position="bottom-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={true}
          rtl={false}
          closeOnClick
        />
			</div>
				<div className="max-w-screen mx-auto px-2 sm:px-6 lg:px-8 sm:pt-5 w-80 sm:w-[650px] items-center">
				<div className="flex justify-center pt-20 sm:justify-start flex-row sm:pt-0">
					<h1 className="text-6xl font-bold dark:text-white shrink">{APP_NAME}</h1> <div className="hidden text-4xl sm:text-6xl dark:text-white px-5 sm:flex">on </div><div className="sm:flex hidden text-4xl sm:text-6xl text-violet-900 dark:text-violet-900"> Farcaster</div>
				</div>

				<div className="mt-8 bg-white dark:bg-gray-800 overflow-hidden shadow sm:rounded-lg m-auto w-80 sm:w-[650px]">
					<div className="grid grid-cols-1 md:grid-cols-1">
						<div className="p-3">
							<div className="flex items-center">
								
								<div className="mt-2 mb-4 dark:text-white m-auto text-xl leading-7 font-semibold">
									Deposit Tips
								</div>
							</div>

		<div className="w-full">
			<Listbox value={tokenHash} onChange={setTokenHash}>
                    <div className="relative mt-1 m-2">
                      <Listbox.Button className="relative z-[100] w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="bold-paragraph block truncate">
                           {selectToken == ''
                            ? 'Select Token to Deposit'
                            : selectToken}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        
                        </span>
                      </Listbox.Button>                      
					  <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
					  <Listbox.Options className="absolute z-[100] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {tokens.map((token) => (
                              <Listbox.Option
                                key={token.tokenSymbol}
                                className={({ active }) =>
                                  `bold-paragraph relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? 'bg-amber-100 text-amber-900'
                                      : 'text-gray-900'
                                  }`
                                }
                                value={token.hashval}
                                onClick={()=>{checkBalance(token.hashval);setSelectToken(tokens.find(isSelectedToken).title );}}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                      }`}
                                    >
                                     {token.title} 
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                        {/* <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        /> */}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
					  </Transition>
                    </div>
                  </Listbox>
				  <div className="m-2 dark:text-white">How many {tokenSymbol} to deposit?</div>
				  <div className="m-2 border focus-within:shadow-md rounded-md overflow-hidden h-[50px]">
          <input
            type="number"
            placeholder="1000"
            value={tipSharesAmount}
            onChange={handleInputChange(setTipSharesAmount)}
            className="outline-none border-0 px-2 w-full h-full"
          />
          </div>
		  <div className="m-3"><button
            onClick={handleBuyTipShares}
            className="btn btn-primary mt-4 m-auto mt-3 w-full rounded-lg bg-gradient-to-r from-indigo-500 to-purple-500 pb-10 pb-2 pl-4 pr-4 pt-2 text-sm font-bold uppercase tracking-tighter text-white shadow-xl"
                         
          >
            Deposit
          </button></div>
        </div>
        <div className="m-2 dark:text-white text-center items-center">Your {tokenSymbol} balance: {balance}</div>
        <div className="dark:text-white text-sm m-auto w-full px-3 relative flex items-top justify-center dark:bg-gray-800 text-center items-center py-2  sm:pt-0">
		Sponsored gas & development 100% powered by community donations. Thank you!</div>
    <div className="dark:text-white text-xs m-auto w-2/4 relative flex items-top justify-center dark:bg-gray-800 items-center py-2  sm:pt-0">
		0x6c4266bDb5c82aa2eC154104E5445A3e69e50Eaa</div>
						</div>
					</div>
				</div>
     
			</div>

		</div>		

    </div>
	)
}

export default App;
