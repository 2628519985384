// src/ethersProvider.js
import { ethers } from 'ethers';


const alchemyUrl = 'https://base-mainnet.g.alchemy.com/v2/kX8tT8NvetwmHKARUdQkm2D15jhukcFZ';
const network = {
    chainId: 8453,          // Required: Specify the chain ID
};

// Initialize the provider with the custom network configuration
const provider = new ethers.providers.JsonRpcProvider(alchemyUrl, network);


export default provider;
