export const shakaShareContract = {
    address: '0x6B5DE9F7322bd4b612A45001690fee52E7e9EE73',
    abi:[
		{
		  "inputs": [],
		  "stateMutability": "nonpayable",
		  "type": "constructor"
		},
		{
		  "inputs": [],
		  "name": "InsufficientBalance",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "InvalidAmount",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "InvalidIncentiveOwner",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "InvalidJar",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "InvalidJarOwner",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "InvalidJarSigner",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "InvalidTipRecipient",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "PausedJar",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "UninitializedJar",
		  "type": "error"
		},
		{
		  "inputs": [],
		  "name": "UnsupportedTokenAddress",
		  "type": "error"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "from",
			  "type": "address"
			},
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "to",
			  "type": "address"
			},
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": false,
			  "internalType": "bytes",
			  "name": "castID",
			  "type": "bytes"
			}
		  ],
		  "name": "FirstTipClaimed",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": true,
			  "internalType": "string",
			  "name": "incentiveName",
			  "type": "string"
			},
			{
			  "indexed": true,
			  "internalType": "enum IShareTips.IncentiveType",
			  "name": "incentiveType",
			  "type": "uint8"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "incentiveIndex",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "address",
			  "name": "to",
			  "type": "address"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "amount",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "address",
			  "name": "incentiveOwner",
			  "type": "address"
			}
		  ],
		  "name": "IncentiveDistributed",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": true,
			  "internalType": "string",
			  "name": "incentiveName",
			  "type": "string"
			},
			{
			  "indexed": true,
			  "internalType": "enum IShareTips.IncentiveType",
			  "name": "incentiveType",
			  "type": "uint8"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "incentiveAmountPerEvent",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "numEventsFunded",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "startTimestamp",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "endTimestamp",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "address",
			  "name": "creator",
			  "type": "address"
			}
		  ],
		  "name": "IncentiveInitialized",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": true,
			  "internalType": "uint256",
			  "name": "incentiveIndex",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			}
		  ],
		  "name": "IncentivePaused",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": false,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": false,
			  "internalType": "address",
			  "name": "tokenAddress",
			  "type": "address"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "tokenPerTip",
			  "type": "uint256"
			}
		  ],
		  "name": "InitTipJar",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": false,
			  "internalType": "uint8",
			  "name": "version",
			  "type": "uint8"
			}
		  ],
		  "name": "Initialized",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "depositor",
			  "type": "address"
			},
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "tipQty",
			  "type": "uint256"
			}
		  ],
		  "name": "JarDeposit",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": false,
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			}
		  ],
		  "name": "JarPaused",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "withdrawer",
			  "type": "address"
			},
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "tipQty",
			  "type": "uint256"
			}
		  ],
		  "name": "JarWithdrawal",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "previousOwner",
			  "type": "address"
			},
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "newOwner",
			  "type": "address"
			}
		  ],
		  "name": "OwnershipTransferred",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": false,
			  "internalType": "address",
			  "name": "account",
			  "type": "address"
			}
		  ],
		  "name": "Paused",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": false,
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "indexed": false,
			  "internalType": "address",
			  "name": "signer",
			  "type": "address"
			},
			{
			  "indexed": false,
			  "internalType": "bool",
			  "name": "value",
			  "type": "bool"
			}
		  ],
		  "name": "SignerUpdate",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "from",
			  "type": "address"
			},
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "to",
			  "type": "address"
			},
			{
			  "indexed": true,
			  "internalType": "bytes",
			  "name": "hash",
			  "type": "bytes"
			},
			{
			  "indexed": false,
			  "internalType": "uint256",
			  "name": "amount",
			  "type": "uint256"
			},
			{
			  "indexed": false,
			  "internalType": "bytes",
			  "name": "castID",
			  "type": "bytes"
			}
		  ],
		  "name": "Tip",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": true,
			  "internalType": "address",
			  "name": "tokenAddress",
			  "type": "address"
			},
			{
			  "indexed": false,
			  "internalType": "bool",
			  "name": "enabled",
			  "type": "bool"
			}
		  ],
		  "name": "TokenChanged",
		  "type": "event"
		},
		{
		  "anonymous": false,
		  "inputs": [
			{
			  "indexed": false,
			  "internalType": "address",
			  "name": "account",
			  "type": "address"
			}
		  ],
		  "name": "Unpaused",
		  "type": "event"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			}
		  ],
		  "name": "activeIncetivesForJar",
		  "outputs": [
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			},
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "name": "addressesFirstTipFromByToken",
		  "outputs": [
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			},
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "name": "balanceOfJars",
		  "outputs": [
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "index",
			  "type": "uint256"
			}
		  ],
		  "name": "deactivateIncentive",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "tipQty",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "tokenQty",
			  "type": "uint256"
			}
		  ],
		  "name": "depositIntoJar",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "tipQty",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "tokenQty",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "deadline",
			  "type": "uint256"
			},
			{
			  "internalType": "uint8",
			  "name": "v",
			  "type": "uint8"
			},
			{
			  "internalType": "bytes32",
			  "name": "r",
			  "type": "bytes32"
			},
			{
			  "internalType": "bytes32",
			  "name": "s",
			  "type": "bytes32"
			}
		  ],
		  "name": "depositIntoJarWithPermit",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "address",
			  "name": "tokenAddress",
			  "type": "address"
			}
		  ],
		  "name": "disableToken",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "address",
			  "name": "tokenAddress",
			  "type": "address"
			}
		  ],
		  "name": "enableToken",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			}
		  ],
		  "name": "enabledTokensForTipping",
		  "outputs": [
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [],
		  "name": "getAllJarDetails",
		  "outputs": [
			{
			  "components": [
				{
				  "internalType": "bytes",
				  "name": "jarID",
				  "type": "bytes"
				},
				{
				  "internalType": "address",
				  "name": "tokenAddress",
				  "type": "address"
				},
				{
				  "internalType": "uint256",
				  "name": "tokenPerTip",
				  "type": "uint256"
				},
				{
				  "internalType": "bool",
				  "name": "paused",
				  "type": "bool"
				},
				{
				  "internalType": "string",
				  "name": "name",
				  "type": "string"
				},
				{
				  "internalType": "address",
				  "name": "owner",
				  "type": "address"
				},
				{
				  "internalType": "bool",
				  "name": "nonOwnerIncentivesEnabled",
				  "type": "bool"
				}
			  ],
			  "internalType": "struct IShareTips.TipJar[]",
			  "name": "",
			  "type": "tuple[]"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "string",
			  "name": "name",
			  "type": "string"
			},
			{
			  "internalType": "address",
			  "name": "owner",
			  "type": "address"
			},
			{
			  "internalType": "address",
			  "name": "tokenAddress",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "tokenPerTip",
			  "type": "uint256"
			},
			{
			  "internalType": "bool",
			  "name": "nonOwnerIncentivesEnabled",
			  "type": "bool"
			}
		  ],
		  "name": "initJar",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [],
		  "name": "initialize",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "string",
			  "name": "incentiveName",
			  "type": "string"
			},
			{
			  "internalType": "enum IShareTips.IncentiveType",
			  "name": "incentiveType",
			  "type": "uint8"
			},
			{
			  "internalType": "uint256",
			  "name": "incentiveAmountPerEvent",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "numEventsFunded",
			  "type": "uint256"
			},
			{
			  "internalType": "enum IShareTips.Benefiter",
			  "name": "benefiter",
			  "type": "uint8"
			},
			{
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			},
			{
			  "internalType": "uint256",
			  "name": "startTimestamp",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "endTimestamp",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "redemptionsPerAddress",
			  "type": "uint256"
			}
		  ],
		  "name": "initializeIncentive",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			}
		  ],
		  "name": "jarIDToJar",
		  "outputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "address",
			  "name": "tokenAddress",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "tokenPerTip",
			  "type": "uint256"
			},
			{
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			},
			{
			  "internalType": "string",
			  "name": "name",
			  "type": "string"
			},
			{
			  "internalType": "address",
			  "name": "owner",
			  "type": "address"
			},
			{
			  "internalType": "bool",
			  "name": "nonOwnerIncentivesEnabled",
			  "type": "bool"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			}
		  ],
		  "name": "jarIDs",
		  "outputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "string",
			  "name": "name",
			  "type": "string"
			},
			{
			  "internalType": "address",
			  "name": "jarOwner",
			  "type": "address"
			},
			{
			  "internalType": "bool",
			  "name": "nonOwnerIncentivesEnabled",
			  "type": "bool"
			}
		  ],
		  "name": "modifyJar",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "address",
			  "name": "signer",
			  "type": "address"
			},
			{
			  "internalType": "bool",
			  "name": "value",
			  "type": "bool"
			}
		  ],
		  "name": "modifySigner",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [],
		  "name": "owner",
		  "outputs": [
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bool",
			  "name": "_paused",
			  "type": "bool"
			}
		  ],
		  "name": "pause",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [],
		  "name": "paused",
		  "outputs": [
			{
			  "internalType": "bool",
			  "name": "",
			  "type": "bool"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			},
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "name": "redemptionsPerIncentivePerAddress",
		  "outputs": [
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [],
		  "name": "renounceOwnership",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "incentiveIndex",
			  "type": "uint256"
			},
			{
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			}
		  ],
		  "name": "setIncentivePause",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			}
		  ],
		  "name": "setJarPaused",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "bool",
			  "name": "enabled",
			  "type": "bool"
			}
		  ],
		  "name": "setNonOwnerIncentives",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			},
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "name": "signersOfJars",
		  "outputs": [
			{
			  "internalType": "bool",
			  "name": "",
			  "type": "bool"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "address",
			  "name": "from",
			  "type": "address"
			},
			{
			  "internalType": "address",
			  "name": "to",
			  "type": "address"
			},
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "tipQty",
			  "type": "uint256"
			},
			{
			  "internalType": "bytes",
			  "name": "castID",
			  "type": "bytes"
			}
		  ],
		  "name": "tip",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "",
			  "type": "uint256"
			}
		  ],
		  "name": "tipIncentives",
		  "outputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "string",
			  "name": "name",
			  "type": "string"
			},
			{
			  "internalType": "enum IShareTips.IncentiveType",
			  "name": "incentiveType",
			  "type": "uint8"
			},
			{
			  "internalType": "uint256",
			  "name": "incentiveAmountPerEvent",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "currentBalance",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "startTimestamp",
			  "type": "uint256"
			},
			{
			  "internalType": "uint256",
			  "name": "endTimestamp",
			  "type": "uint256"
			},
			{
			  "internalType": "bool",
			  "name": "paused",
			  "type": "bool"
			},
			{
			  "internalType": "address",
			  "name": "owner",
			  "type": "address"
			},
			{
			  "internalType": "uint256",
			  "name": "redemptionsPerAddress",
			  "type": "uint256"
			},
			{
			  "internalType": "enum IShareTips.Benefiter",
			  "name": "benefiter",
			  "type": "uint8"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "address",
			  "name": "",
			  "type": "address"
			}
		  ],
		  "name": "tokenEnabledForTipping",
		  "outputs": [
			{
			  "internalType": "bool",
			  "name": "",
			  "type": "bool"
			}
		  ],
		  "stateMutability": "view",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "address",
			  "name": "newOwner",
			  "type": "address"
			}
		  ],
		  "name": "transferOwnership",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		},
		{
		  "inputs": [
			{
			  "internalType": "bytes",
			  "name": "jarID",
			  "type": "bytes"
			},
			{
			  "internalType": "uint256",
			  "name": "tipQty",
			  "type": "uint256"
			}
		  ],
		  "name": "withdrawFromJar",
		  "outputs": [],
		  "stateMutability": "nonpayable",
		  "type": "function"
		}
	  ]};


export const shakaTokenContract = {
      address: '0x478e03D45716dDa94F6DbC15A633B0D90c237E2F',
      abi:[
        {
          "inputs": [],
          "stateMutability": "nonpayable",
          "type": "constructor"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "allowance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "needed",
              "type": "uint256"
            }
          ],
          "name": "ERC20InsufficientAllowance",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "sender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "balance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "needed",
              "type": "uint256"
            }
          ],
          "name": "ERC20InsufficientBalance",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "approver",
              "type": "address"
            }
          ],
          "name": "ERC20InvalidApprover",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "receiver",
              "type": "address"
            }
          ],
          "name": "ERC20InvalidReceiver",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "sender",
              "type": "address"
            }
          ],
          "name": "ERC20InvalidSender",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "ERC20InvalidSpender",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "NoZeroValueTransfers",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            }
          ],
          "name": "OwnableInvalidOwner",
          "type": "error"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "OwnableUnauthorizedAccount",
          "type": "error"
        },
        {
          "inputs": [],
          "name": "TokenTransfersArePaused",
          "type": "error"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Approval",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "bool",
              "name": "state",
              "type": "bool"
            }
          ],
          "name": "ContractPaused",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "previousOwner",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "OwnershipTransferred",
          "type": "event"
        },
        {
          "anonymous": false,
          "inputs": [
            {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "Transfer",
          "type": "event"
        },
        {
          "inputs": [],
          "name": "MAX_SUPPLY",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address[]",
              "name": "addressesToAirdop",
              "type": "address[]"
            },
            {
              "internalType": "uint256[]",
              "name": "amountsToAirdrop",
              "type": "uint256[]"
            }
          ],
          "name": "airdropMany",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "allowance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "approve",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "burn",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "decimals",
          "outputs": [
            {
              "internalType": "uint8",
              "name": "",
              "type": "uint8"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "isPaused",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "name",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "owner",
          "outputs": [
            {
              "internalType": "address",
              "name": "",
              "type": "address"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "renounceOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "bool",
              "name": "paused",
              "type": "bool"
            }
          ],
          "name": "setPause",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "symbol",
          "outputs": [
            {
              "internalType": "string",
              "name": "",
              "type": "string"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "totalSupply",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "transfer",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "from",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "to",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
            }
          ],
          "name": "transferFrom",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "newOwner",
              "type": "address"
            }
          ],
          "name": "transferOwnership",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        }
      ]};
  
