import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { useEthers } from '@usedapp/core';

//import provider from '../ethersProvider.js';

const ConnectWallet = () => {
    // const [account, setAccount] = useState("");
    // const [chainId, setChainId] = useState('8453');
   // const provider = new ethers.providers.getDefaultProvider();
    //const alchemyUrl = 'https://base-mainnet.g.alchemy.com/v2/kX8tT8NvetwmHKARUdQkm2D15jhukcFZ';
    //const provider = new ethers.providers.JsonRpcProvider(alchemyUrl);
    const correctNetworkId = '8453'; // For Ethereum Mainnet; use '3' for Ropsten, etc.
    
  const { account, chainId } = useEthers();
  const { activateBrowserWallet } = useEthers()
    // Retrieve network information

    useEffect(() => {

        if (window.ethereum) {
                  
        const handleChainChanged = (_chainId) => {
            // Ethers returns chainId as a hex string
            const parsedChainId = parseInt(_chainId, 16);
            console.log("Chain ID changed:", _chainId);
            console.log("Parsed Chain ID:", parsedChainId);
            console.log("Correct Network ID:", correctNetworkId);
            if (parsedChainId.toString() !== correctNetworkId) {
                toast.error(`You're connected to the wrong network. Please switch to the correct network.`);
            }
        };

        window.ethereum.on('chainChanged', handleChainChanged);

        return () => {
            window.ethereum.removeListener('chainChanged', handleChainChanged);
        };}
    }, []);
    // Call getNetworkDetails on component mount

    function shortenAddress(address, chars = 4) {
        if (!address) return "";
        return `${address.substring(0, chars + 2)}...${address.substring(address.length - chars)}`;
    }

    const connectWalletHandler = async () => {
        if (window.ethereum) {
            try {
                // Request account access
                // const newAccounts = await window.ethereum.request({ method: 'eth_requestAccounts'});
                // console.log("accounts:", newAccounts);
                if (account) {
                    
                }else{
                activateBrowserWallet();
                }
                // Optionally check if on the correct network
                if (chainId && chainId.toString() !== correctNetworkId) {
                    toast.error(`Please switch to the correct network!`);
                }

            } catch (error) {
                console.error('Error connecting to MetaMask', error);
            }
        } else {
            console.log("Please install MetaMask!");
        }
    };

    return (
        <div>
            <button onClick={connectWalletHandler} className="btn btn-primary mt-4 m-auto mt-3 w-full rounded-lg bg-gradient-to-r to-indigo-400 from-purple-800 pb-10 pb-2 pl-4 pr-4 pt-2 text-sm font-bold uppercase tracking-tighter text-white shadow-xl">
                {account ? `Connected: ${shortenAddress(account)}` : "Connect Wallet"}
            </button>
            {account && <p>{chainId && chainId.toString() === correctNetworkId ? "" : `Wrong Network - Switch to Base`}</p>}
        </div>
    );
};

export default ConnectWallet;
