import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {  DAppProvider, Base, Mainnet } from '@usedapp/core';

const config = {
  readOnlyChainId: Base.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://eth-mainnet.g.alchemy.com/v2/-dkJhgMp3dGQkhK7ABLo9FUfNhU2LAnq',
    [Base.chainId]: 'https://base-mainnet.g.alchemy.com/v2/kX8tT8NvetwmHKARUdQkm2D15jhukcFZ',
  },
  network: Base,
  supportedChains: [8453],
};



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <DAppProvider config={config}>
      <React.StrictMode>
    <App />
    </React.StrictMode>
    </DAppProvider>

);

